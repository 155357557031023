@import "../../variables.scss";
.ManageDistributionTableStyles {
    .ant-table-content {
        @media #{$large-screen} {
            overflow-x: auto;
            max-width: 100%;
        }
    }
    
  .ant-table-thead > tr > th:last-child,
  .ant-table tfoot > tr > th:last-child {
    background: $White;
    border-bottom: 1px solid $Border-Grey;
    right: 0;
    top: auto;
    position: sticky;
    z-index: 3;
    text-align: right;
  }


  .Table_block_main {
    .ant-table-thead > tr > th .ant-table-filter-column-title {
      white-space: nowrap;
      flex: initial;
      padding-right: 0;
    }
    .ant-table-thead > tr > th {
      .ant-table-filter-trigger-container {
        position: static;
        .ant-table-filter-trigger {
          position: relative;
        }
      }
    }
    .ant-table-tbody > tr > td {
      white-space: normal;
    }
  }

  .useremails {
    white-space: normal;
  }

  .ant-table-tbody > tr > td:last-child, 
  .ant-table tfoot > tr > td:last-child {
    background: $White;
    right: 0;
    top: auto;
    position: sticky;
    z-index: 3;
    text-align: right;
  }

  .ant-table-thead {
    tr {
      &:hover {
        th:last-child {
          background: $White;
        }
      }
    }
  }
  .ant-table-tfoot {
    tr {
      &:hover {
        th:last-child {
          background: $Light-Gray-fa;
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      &:hover {
        td:last-child {
          background: $Light-Gray-fa;
        }
      }
    }
    .ant-table-cell {
      .ant-select-selector {
        min-width: 70px;
      }
    }
  }
  .ant-table-tfoot {
    tr {
      &:hover {
        td:last-child {
          background: $Light-Gray-fa;
        }
      }
    }
  }

  }

  .DistributionFormStyles {
    .DistributionForm_container {
        .AddCatalog__title {
          font-size: 20px;
          line-height: 22px;
          color: $Dark-Blue;
          text-transform: capitalize;
          padding: 0 0 20px;
        }
        .DistributionForm_whitebox {
          max-width: 60%;
          margin: 0 0 24px;
          @media #{$IpadP} {
            max-width: 100%;
           }
           .multiple_select {
             .ant-select-selector {
              height: auto;
             }
           }
        }
      }  
}
.loader1 {
  position: fixed;
  left: 40%;
  top: 35%;
  z-index: 5;
}
